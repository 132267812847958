$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    // add border to active element
    $(".js-header-nav > ul > li.active").addClass("border");

    // add border to hovered element and open submenu
    $(".js-header-nav > ul > li > a").mouseenter(function() {
        $(this).parent(".js-header-nav > ul > li").addClass("open border").siblings("li").removeClass("open border");
    });

    $(".js-header-nav li.active").addClass("border");

    // remove border when element is left
    $(".js-header-nav > ul > li > a").mouseleave(function() {
        if ($(this).siblings("ul").length === 0) {
            $(this).parent("li").removeClass("open border");
            $(".js-header-nav li.active").addClass("border");
        } else {
            $(this).siblings("ul").mouseleave(function() {
                $(this).parent("li").removeClass("open border");
                $(".js-header-nav li.active").addClass("border");
            })
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobileNavigation
    // *
    // *
    $(".js-nav-mobile-button, .js-nav-mobile a").click(function(){
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    });

    $('.js-nav-mobile span').click(function(){
        $(this).closest('li').toggleClass('is-open is-closed').find('> ul').slideToggle();
    });

    // trigger active menü-parents
    $('.js-nav-mobile .active > span').trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * introSlider
    // *
    // *
    var $introSlider = $(".js-intro-slider");

    if ($introSlider.length){
        $introSlider.each(function(){
            $(this).slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3500,
                speed: 1000,
                prevArrow: $(this).parent(".js-pagingbutton-wrapper").find(".js-pagingbutton-nav-prev"),
                nextArrow: $(this).parent(".js-pagingbutton-wrapper").find(".js-pagingbutton-nav-next"),
                responsive: [
                        {
                          breakpoint: 1150,
                          settings: {
                            arrows:false
                          }
                        }
                      ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * infoCarousel
    // *
    // *
    var $teaserCarousel = $(".js-teaser-carousel");

    if ($teaserCarousel.length){
        $teaserCarousel.each(function(){
            $(this).slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: true,
                // autoplay: true,
                // autoplaySpeed: 3500,
                speed: 1000,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                adaptiveHeight: true,
                prevArrow: $(this).parents(".js-pagingbutton-wrapper").find(".js-pagingbutton-nav-prev"),
                nextArrow: $(this).parents(".js-pagingbutton-wrapper").find(".js-pagingbutton-nav-next"),
                responsive: [
                        {
                          breakpoint: 550,
                          settings: {
                            slidesToShow: 1,
                            centerMode: false,
                          }
                        }
                      ]
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * infoCarousel
    // *
    // *
    var $infoCarousel = $(".js-info-carousel");

    if ($infoCarousel.length){
        $infoCarousel.each(function(){
            $(this).slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: true,
                // autoplay: true,
                // autoplaySpeed: 3500,
                speed: 1000,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                adaptiveHeight: true,
                prevArrow: $(this).parents(".js-pagingbutton-wrapper").find(".js-pagingbutton-nav-prev"),
                nextArrow: $(this).parents(".js-pagingbutton-wrapper").find(".js-pagingbutton-nav-next"),
                responsive: [
                        {
                          breakpoint: 550,
                          settings: {
                            slidesToShow: 1,
                            centerMode: false,
                          }
                        }
                      ]
            });
        });
    }


    if ($infoCarousel.length) {
        // check if hash exist
        if(window.location.hash) {
            var hash = $(location).prop('hash').substr(1);
            $infoCarousel.slick('slickGoTo', hash - 1);
        }

        $(".js-menu li a").click(function(event){
            var url = $(this).attr("href");
            var index = url.indexOf("#");
            if (index !== -1)
            {
                var hash = url.substring(index + 1);
            }

            $infoCarousel.slick('slickGoTo', hash - 1);
        });
    }






    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * more button
    // *
    // *
    $(".js-more-button").click(function(){
        $(this).toggleClass("active").parents(".js-more-wrapper").find(".js-more-content").slideToggle();
    });









});
